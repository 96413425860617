@import '../../assets/styles/var';

.logo {
  display: inline-flex;
  width: 224px;
  height: 44px;
  align-items: center;
  color: $black;

  .brand-name {
    font-family: $header_text;
    margin-right: 12px;
    font-size: 18px;
    font-weight: bold;
  }

  .divider {
    width: 1px;
    height: 35px;
    margin-right: 12px;
    margin-left: 12px;
    background-color: #aaaaaa;
  }

  .by {
    font-size: 14px;
  }

  .ap-brand {
    background: transparent url('../../assets/images/AP.svg') 0% 0% no-repeat
      padding-box;
    opacity: 1;
    width: 26px;
    height: 26px;
    margin-left: 10px;
  }

  .app-brand {
    width: 300px;
    height: 50px;
    margin-left: 10px;
  }
}
