/* @import '~antd/dist/antd.css'; */

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'TTNormsProRegular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666;
  background-color: #fff;
  font-size: 1rem;
  line-height: 27px;
  height: 100%;
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background: #299f9e;
}

body > div {
  height: 100%;
}

h1,
h2,
h3,
h4 {
  font-family: 'TTNormsProBold', sans-serif;
  font-weight: bold;
}

p,
h1,
h2,
h3,
h4 {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: underline;
  color: inherit;
}

p {
  margin-bottom: 5px;
  display: block;
}

li {
  list-style: none;
}

.f-rt {
  float: right;
}

.mr-6 {
  margin-right: 6rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.m-0 {
  margin-top: 0 !important;
}

.fw-bold {
  font-weight: bold;
}

.app-modal .ant-modal-close-x {
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #299f9e;
}

.app-modal .ant-modal-close-x .ant-modal-close-icon {
  background-color: #fff;
  padding: 4px;
  border-radius: 50%;
}

.required-label {
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  margin-left: -0.6rem;
}
