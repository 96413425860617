/* 
***** ATTENTION ******
This next line solves a bug that occurs when you 
click on ANY select component and you can't interact with any part of the page

Reference: ask Suulola & Simeon
*/
body > div {
  height: 0% !important;
}

html {
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}
/* body > div:nth-child(9) {
  display: none;
} */

.ant-select-disabled .ant-select-selection-item {
  color: black !important;
}
