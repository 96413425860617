@import '../../assets/styles/var';

.nav-header-wrapper {
  width: 100%;
  position: relative;

  .hamburger {
    display: none;
    font-size: 22px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 10%;
    transition: 250ms ease;
    color: $accent_green;
    cursor: pointer;

    @media only screen and (max-width: 1089px) {
      display: block;
    }

    @media only screen and (max-width: 768px) {
      right: 20px;
      font-size: 24px;
    }
  }
}

.header-wrapper {
  font-size: 14px;
  width: 100%;
  padding: 20px 0;
  border-bottom: 2px solid #f4f7fa;

  & .breadcrumb {
    display: flex;
    margin-bottom: 16px;
    margin-left: -64px;

    & .back-btn {
      background-color: transparent;
      border-color: transparent;
      border-right: 1px solid #f4f7fa;
      padding-right: 15px;
      margin-right: 15px;
      cursor: pointer;
    }

    & img {
      margin-right: 8px;
    }

    & .module {
      color: $primary;
      font-weight: bold;

      &::after {
        content: '>';
        margin: 0 4px;
      }
    }
  }

  & .page_title {
    font-size: 30px;
  }
}

.module-header {
  font-size: 14px;
  width: 100%;
  border-bottom: 2px solid #f4f7fa;

  .breadcrumb {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    font-size: 16px;

    button {
      background: transparent;
      border: 0px;
      font-weight: bold;
      display: flex;
      align-items: center;
      padding-top: 0.1rem;
      cursor: pointer;
    }

    .divider {
      margin: 0rem 1rem;
      background-color: #b7b7b7;
      width: 1px;
      height: 20px;
    }

    a {
      color: $primary;
      display: flex;
      align-items: center;
      font-weight: bold;

      img {
        margin-right: 5px;
      }
    }

    .caret {
      margin: 0rem 0.5rem;
    }

    .module {
      font-size: 14px;
      // margin-top: 2px;
      display: flex;

      // & > a:not(:last-child)::after {
      //   content: '>';
      //   padding: 1rem;
      //   pointer-events: none;
      // }
    }
  }

  .title {
    margin-left: 9.5rem;
    margin-bottom: 0.5rem;
    font-size: 20px;
  }
}
